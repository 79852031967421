import { withDependencies, optional, named } from '@wix/thunderbolt-ioc'
import { SdkHandlersProvider, SiteFeatureConfigSymbol } from '@wix/thunderbolt-symbols'
import { INavigation, NavigationSymbol } from 'feature-navigation'
import { IUrlHistoryManager, UrlHistoryManagerSymbol, IUrlChangeHandler } from 'feature-router'
import { CustomUrlMapperSymbol, ICustomUrlMapper, ICustomUrlLocationHandler } from 'feature-custom-url-mapper'
import { LocationWixCodeSdkSiteConfig } from '../types'
import { name } from '../symbols'

export const locationWixCodeSdkHandlersProvider = withDependencies(
	[NavigationSymbol, UrlHistoryManagerSymbol, named(SiteFeatureConfigSymbol, name), optional(CustomUrlMapperSymbol)],
	(
		navigation: INavigation,
		urlHistoryManager: IUrlHistoryManager,
		{ baseUrl }: LocationWixCodeSdkSiteConfig,
		customUrlMapper: ICustomUrlMapper | undefined
	): SdkHandlersProvider<Record<string, Function> & ICustomUrlLocationHandler> & IUrlChangeHandler => {
		const onChangeHandlers: Array<Function> = []

		return {
			getSdkHandlers: () => ({
				navigateTo: navigation.navigateTo,
				pushUrlState: (href: string): void => {
					const url = new URL(href)
					urlHistoryManager.pushUrlState(url)
				},
				registerLocationSdkOnChangeHandler: (handler: Function) => {
					onChangeHandlers.push(handler)
				},
				buildCustomizedUrl: async (key, itemData, options) => {
					const urlMappings = customUrlMapper?.urlMappings

					const { buildCustomizedUrl } = await import(
						'@wix/url-mapper-utils' /* webpackChunkName: "url-mapper-utils" */
					)
					return buildCustomizedUrl(urlMappings, key, itemData, { baseUrl, ...options })
				},
			}),
			onUrlChange: async (url) => {
				onChangeHandlers.forEach((handler) => handler(url.href))
			},
		}
	}
)
